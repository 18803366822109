<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="4">
        <v-card>
          <v-card-text>
            <v-card-title>
              <v-icon left>mdi-file-document-box</v-icon>
              Audit Log
            </v-card-title>
            <v-divider></v-divider>

            <!-- Date Range Picker -->
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Date Range"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDateRange"
                range
                @change="fetchFilteredLogs"
                max-date="today"
              ></v-date-picker>
            </v-menu>

            <!-- User Filter by ID -->
            <v-autocomplete
              v-model="selectedUser"
              :items="userList"
              item-text="name"
              item-value="id"
              label="Filter by User"
              clearable
              class="my-4"
              prepend-icon="mdi-account-search"
              @change="fetchFilteredLogs"
            ></v-autocomplete>

            <!-- Action Type Filter -->
            <v-select
              v-model="selectedActionType"
              :items="actionTypes"
              label="Filter by Action Type"
              clearable
              class="my-4"
              prepend-icon="mdi-filter"
              @change="fetchFilteredLogs"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card >
         

          <v-data-table
            :headers="headers"
            :items="filteredLogs"
            class="elevation-1"
            :items-per-page="20"
            :loading="loading"
            loading-text="Loading logs..."
            order-by="timestamp"
            order-desc
            :search="search"
            mobile-breakpoint="0"
          >

          <!-- table header with search -->

            <template v-slot:top>
              <v-toolbar flat class="pl-2">
                <v-toolbar-title>Logs</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>







            <template v-slot:item.actionType="{ item }">
              <v-chip :color="chipColor(item.actionType)" dark>
                {{ item.actionType.toUpperCase() }}
              </v-chip>
            </template>

            <template v-slot:item.timestamp="{ item }">
              <span>{{ getFormattedDate(item.timestamp.seconds) }}</span>
            </template>

            <template v-slot:item.details="{ item }">
              <span>{{ item.details }}</span>
            </template>
          </v-data-table>

          <v-alert v-if="!loading && !filteredLogs.length" type="info">
            No logs found for the selected filter.
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, onSnapshot, doc, orderBy, startAt, endAt, getDocs } from "firebase/firestore";
import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      search: null,
      logs: new Map(), // Use a Map to ensure logs are unique by ID
      filteredLogs: [], // Dynamically filtered logs
      userList: [], // List of users
      selectedUser: null, // User ID filter
      selectedActionType: null, // Action type filter
      selectedDateRange: [moment().startOf("month").toDate(), moment().toDate()], // Default range: start of month to today
      dateRangeText: "",
      menu: false,
      actionTypes: ["error", "create", "update", "delete", "read", "export","checkin","ai"], // Log action types
      headers: [
        { text: 'Action Type', value: 'actionType' },
        { text: 'Timestamp', value: 'timestamp'},
        { text: 'User ID', value: 'userId' },
        { text: 'Details', value: 'details' },
      ], // Data table headers
    };
  },
  computed: {
    sortedLogs() {
      return Array.from(this.logs.values())
        .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds); // Sort by most recent
    },
  },
  methods: {
    chipColor(actionType) {
      const colors = {
        create: "green",
        update: "blue",
        delete: "red",
        error: "red",
        read: "purple",
        export: "orange",
        checkin: "cyan",
        ai: "pink",
      };
      return colors[actionType] || "grey";
    },
    updateDateRangeText() {
      if (this.selectedDateRange.length === 2) {
        const [start, end] = this.selectedDateRange;
        this.dateRangeText = `${moment(start).format("MM/DD/YYYY")} - ${moment(end).format("MM/DD/YYYY")}`;
      }
    },
    async fetchDisabledUsers() {
      try {
        const db = getFirestore();
        const usersQuery = query(collection(db, "users"), where("disabled", "==", false));
        const usersSnapshot = await getDocs(usersQuery);
        this.userList = usersSnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().displayName || "Unknown",
        }));
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    listenToLogs() {
      const db = getFirestore();
      this.logs.clear(); // Clear logs on initial load

      const [startDate, endDate] = this.selectedDateRange;
      const startOfDay = moment(startDate).startOf("day").toDate();
      const endOfDay = moment(endDate).endOf("day").toDate();

      // Listen to logs for each action type with proper date range filtering
      this.actionTypes.forEach((actionType) => {
        const actionDocRef = doc(db, "log", actionType);
        const entriesRef = collection(actionDocRef, "entries");
        const q = query(entriesRef, orderBy("timestamp"), startAt(startOfDay), endAt(endOfDay));

        onSnapshot(q, (snapshot) => {
          snapshot.forEach((doc) => {
            this.logs.set(doc.id, { id: doc.id, ...doc.data(), actionType }); // Add/update logs in Map
          });
          this.updateFilteredLogs(); // Update filtered logs dynamically
                this.loading = false; // Ensure loading state is false after first load

        });
      });

    },
    updateFilteredLogs() {
      const [startDate, endDate] = this.selectedDateRange;

      let logs = Array.from(this.logs.values()).filter((log) => {
        const logTimestamp = moment(log.timestamp.seconds * 1000).toDate();
        const withinDateRange =
          logTimestamp >= moment(startDate).startOf("day").toDate() &&
          logTimestamp <= moment(endDate).endOf("day").toDate();

        const byUser = !this.selectedUser || log.userId === this.selectedUser;
        const byActionType = !this.selectedActionType || log.actionType === this.selectedActionType;

        return withinDateRange && byUser && byActionType;
      });

        //sort logs by timestamp
        logs.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

        this.filteredLogs = logs;
    

    },
    fetchFilteredLogs() {
      this.updateFilteredLogs(); // Dynamically update the filtered logs
    },
    getFormattedDate(timestamp) {
      return moment(timestamp * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  async mounted() {
    this.loading = true; // Set loading state at the start
    await this.fetchDisabledUsers();
    this.updateDateRangeText(); // Initialize date range text
    this.listenToLogs(); // Start listening to Firestore updates
  },
};
</script>

<style scoped>
.v-date-picker-table .v-btn.v-btn--active {
  color: #000 !important;
}
</style>
